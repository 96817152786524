import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  RegisterUserRequest,
  RegisterUserResponse,
  REGISTER_USER,
  RequestEmailVerificationCodeRequest,
  RequestEmailVerificationCodeResponse,
  REQUEST_EMAIL_VERIFICATION_CODE,
  SetProfileRequest,
  SetProfileResponse,
  SET_PROFILE,
  SubmitEmailVerificationCodeRequest,
  SubmitEmailVerificationCodeResponse,
  SUBMIT_EMAIL_VERIFICATION_CODE,
} from 'shared';
import { IRegistrationService } from 'src/app/registration/registration.service.interface';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService implements IRegistrationService {
  private requestEmailVerificationCodeResponse?: RequestEmailVerificationCodeResponse;

  constructor(private angularFireFunctions: AngularFireFunctions) {}

  private resetEmailVerification() {
    this.requestEmailVerificationCodeResponse = undefined;
  }

  setProfile(request: SetProfileRequest): Observable<SetProfileResponse> {
    return this.angularFireFunctions.httpsCallable<FunctionsSetProfileRequest, FunctionsSetProfileResponse>(SET_PROFILE)(request);
  }

  requestEmailVerificationCode(request: RequestEmailVerificationCodeRequest): Observable<RequestEmailVerificationCodeResponse> {
    this.resetEmailVerification();
    return this.angularFireFunctions
      .httpsCallable<FunctionsRequestEmailVerificationCodeRequest, FunctionsRequestEmailVerificationCodeResponse>(
        REQUEST_EMAIL_VERIFICATION_CODE
      )(request)
      .pipe(
        tap((requestEmailVerificationCodeResponse) => {
          this.requestEmailVerificationCodeResponse = requestEmailVerificationCodeResponse;
        })
      );
  }
  submitEmailVerificationCode(request: FunctionsSubmitEmailVerificationCodeRequest): Observable<SubmitEmailVerificationCodeResponse> {
    if (!this.requestEmailVerificationCodeResponse) {
      throw new Error('requestEmailVerificationCodeResponse is undefined');
    }
    return this.angularFireFunctions.httpsCallable<SubmitEmailVerificationCodeRequest, FunctionsSubmitEmailVerificationCodeResponse>(
      SUBMIT_EMAIL_VERIFICATION_CODE
    )({
      ...request,
      factorId: this.requestEmailVerificationCodeResponse.factorId,
    });
  }

  registerUser(request: RegisterUserRequest): Observable<RegisterUserResponse> {
    this.resetEmailVerification();
    return this.angularFireFunctions.httpsCallable<FunctionsRegisterUserRequest, FunctionsRegisterUserResponse>(REGISTER_USER)(request);
  }
}

type FunctionsSubmitEmailVerificationCodeRequest = Readonly<Omit<SubmitEmailVerificationCodeRequest, 'factorId'>>;
type FunctionsSubmitEmailVerificationCodeResponse = Readonly<SubmitEmailVerificationCodeResponse>;
type FunctionsSetProfileRequest = Readonly<SetProfileRequest>;
type FunctionsSetProfileResponse = Readonly<SetProfileResponse>;

type FunctionsRegisterUserResponse = Readonly<RegisterUserResponse>;
type FunctionsRegisterUserRequest = Readonly<RegisterUserRequest>;

type FunctionsRequestEmailVerificationCodeResponse = Readonly<RequestEmailVerificationCodeResponse>;
type FunctionsRequestEmailVerificationCodeRequest = Readonly<RequestEmailVerificationCodeRequest>;
