import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { OktaAuthService } from '@okta/okta-angular';
import { combineLatest, from, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Claims, ExchangeTokenRequest, EXCHANGE_TOKEN, FirebaseAuthToken, OktaAccessToken } from 'shared';
import { toBoolean } from '../utils/operators.rxjs';
import { IAuthenticationService, User } from './authentication.service.interface';
import { ILoginCallbackHandler } from './login-callback-handler.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements IAuthenticationService, ILoginCallbackHandler {
  user = new ReplaySubject<User | undefined>(1);
  isAuthenticated = this.user.pipe(toBoolean);
  constructor(
    private angularFireFunctions: AngularFireFunctions,
    private angularFireAuth: AngularFireAuth,
    private oktaAuthService: OktaAuthService
  ) {
    const oktaAuthStateObs = this.oktaAuthService.$authenticationState;
    const angularFireAuthStateObs = this.angularFireAuth.authState;
    combineLatest([oktaAuthStateObs, angularFireAuthStateObs])
      .pipe(
        map((results) => {
          return { oktaAuthState: results[0], angularFireAuthState: results[1] };
        }),
        switchMap(({ oktaAuthState, angularFireAuthState }) => {
          if (!oktaAuthState || !angularFireAuthState) {
            return of(undefined);
          }
          return this.angularFireAuth.idTokenResult.pipe(
            map((idTokenResults) => {
              if (idTokenResults) {
                const claims = idTokenResults?.claims as Claims;
                const user: User = {
                  uid: angularFireAuthState.uid,
                  claims: {
                    hcpPracticeAdmin: claims.hcpPracticeAdmin,
                  },
                };
                return user;
              } else {
                const user: User = {
                  uid: angularFireAuthState.uid,
                };
                return user;
              }
            })
          );
        })
      )
      .subscribe(this.user);
  }

  async logout(): Promise<void> {
    await Promise.all([this.angularFireAuth.signOut(), this.oktaAuthService.signOut()]);
  }

  login(): Observable<void> {
    return from(
      this.oktaAuthService.signInWithRedirect({
        originalUri: '/',
      })
    );
  }

  async handleLoginCallback() {
    const tokenContainer = await this.oktaAuthService.token.parseFromUrl();
    const accessToken = tokenContainer.tokens.accessToken!.accessToken;
    this.oktaAuthService.tokenManager.add('idToken', tokenContainer.tokens.idToken!);
    this.oktaAuthService.tokenManager.add('accessToken', tokenContainer.tokens.accessToken!);
    const user = await this.firebaseLogin(accessToken);
    /**
     * Check value of user:
     *
     *  falsy: login()
     *  truthy: return
     *
     * Services and CallbackComponents should subscribe to AuthenticationSerivce.isAuthenticated
     * to avoid race conditions between the returned user from the login and the user object
     * propogating through the authentication state.
     */
    if (!user) {
      this.login();
    }
  }

  private async firebaseLogin(accessToken: string): Promise<User | undefined> {
    if (!accessToken) {
      return Promise.reject('Invalid access token');
    }
    const customFirebaseToken = await this.exchangeToken(accessToken).toPromise();
    try {
      const userCredential = await this.angularFireAuth.signInWithCustomToken(customFirebaseToken);
      const user: User = {
        uid: userCredential.user!.uid,
      };
      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private exchangeToken(oktaAccessToken: OktaAccessToken): Observable<FirebaseAuthToken> {
    const request: ExchangeTokenRequest = {
      token: oktaAccessToken,
    };
    return this.angularFireFunctions.httpsCallable<ExchangeTokenRequest, FirebaseAuthToken>(EXCHANGE_TOKEN)(request);
  }
}
