import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirePerformance } from '@angular/fire/performance';
import { EmailValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize, pluck, switchMap } from 'rxjs/operators';
import {
  constants,
  RegExpDefinitions,
  RequestEmailVerificationCodeRequest,
  SetProfileRequest,
  SubmitEmailVerificationCodeRequest,
} from 'shared';
import { LivnStepperComponent } from '../common/components/livn-stepper/livn-stepper.component';
import { PasswordValidator } from '../common/directives/password.directive';
import { CountDownTimer } from '../common/utils/count-down-timer';
import { InvitationsService } from '../invitations/invitations.service';
import { Invitation } from '../invitations/invitations.service.interface';
import { EmailVerificationDialogComponent } from './email-verification-dialog/email-verification-dialog.component';
import { RegistrationService } from './registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [],
})
export class RegistrationComponent implements OnInit {
  createPasswordFormGroup!: FormGroup;
  submitVerificationFormGroup!: FormGroup;
  createProfileFormGroup!: FormGroup;
  forthFormGroup!: FormGroup;
  fithFormGroup!: FormGroup;
  resendVerificationCodeTimer = new CountDownTimer(30);

  invitation = new BehaviorSubject<Invitation | undefined>(undefined);
  invitationSubscription?: Subscription;
  showPassword: boolean = false;
  submitPasswordStepperWorking: boolean = false;
  submitVerificationStepperWorking: boolean = false;
  submitProfileStepperWorking: boolean = false;
  npiEnabled: boolean = true;
  npiValidators = [Validators.required, Validators.pattern(RegExpDefinitions.npi)];
  constants = constants;
  @ViewChild('stepper', { static: true })
  stepper!: LivnStepperComponent;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private invitationsService: InvitationsService,
    private registrationService: RegistrationService,
    private matSnackbar: MatSnackBar,
    private performance: AngularFirePerformance
  ) {}

  private initForms() {
    this.createPasswordFormGroup = this.formBuilder.group({
      email: [{ value: '', disabled: true }, [EmailValidator]],
      password: ['', [Validators.required, PasswordValidator]],
      privicyPolicy: [true, Validators.required],
      termsAndConditions: [true, Validators.required],
    });
    this.submitVerificationFormGroup = this.formBuilder.group({
      verificationCode: ['', [Validators.required, Validators.pattern(RegExpDefinitions.verificationCode)]],
    });
    this.createProfileFormGroup = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      telephone: ['', [Validators.required, Validators.pattern(RegExpDefinitions.telephoneNumber)]],
      npi: [{ value: '', disabled: false }, this.npiValidators],
      doNotHaveNpi: [false],
    });
  }

  private setStep(step: number) {
    for (let index = step; index < this.stepper.steps.length; index++) {
      this.stepper.steps.get(index)!.editable = true;
      this.stepper.steps.get(index)!.completed = false;
    }
    for (let index = 0; index < step; index++) {
      this.stepper.steps.get(index)!.editable = false;
      this.stepper.steps.get(index)!.completed = true;
    }
    this.stepper.selectStepByIndex(step);
  }

  ngOnInit(): void {
    this.initForms();
    this.invitationSubscription = this.activatedRoute.params
      .pipe(
        pluck('id'),
        switchMap((id) => {
          return this.invitationsService.getInvitation(id);
        })
      )
      .subscribe(
        (invitation) => {
          this.createPasswordFormGroup.get('email')?.setValue(invitation.email);
          this.invitation.next(invitation);
          switch (invitation.status) {
            case 'UNREGISTERED':
            case undefined:
              this.setStep(0);
              break;
            case 'REGISTERED':
              this.setStep(1);
              this.requestVerificationCode();
              break;
            case 'EMAIL_VERIFIED':
              this.setStep(2);
              break;
          }
        },
        (error) => {
          this.matSnackbar.open(error.message, 'OK');
        }
      );
  }

  doNotHaveNpiCheckboxChange(event: MatCheckboxChange) {
    this.npiEnabled = !this.npiEnabled;
    this.createProfileFormGroup.patchValue({
      npi: '',
    });
    if (event.checked) {
      this.createProfileFormGroup.get('npiNumber')!.disable();
      this.createProfileFormGroup.get('npiNumber')!.clearValidators();
    } else {
      this.createProfileFormGroup.get('npiNumber')!.setValidators(this.npiValidators);
      this.createProfileFormGroup.get('npiNumber')!.enable();
    }
    this.createProfileFormGroup.updateValueAndValidity();
  }

  openTermsAndConditions(event: Event) {
    window.open('https://www.livanova.com/en-US/Home/Terms-of-Use.aspx', '_blank');
    event.preventDefault();
  }

  openPrivacyPolicy(event: Event) {
    window.open('https://www.livanova.com/en-US/Home/Privacy-Statement.aspx', '_blank');
    event.preventDefault();
  }

  submitPasswordStepper(event: Event) {
    this.submitPasswordStepperWorking = true;
    this.registrationService

      .registerUser({
        email: this.createPasswordFormGroup.get('email')?.value || this.invitation.value!.email,
        password: this.createPasswordFormGroup.get('password')?.value,
        invitationUid: this.invitation.value!.uid,
      })
      .pipe(
        finalize(() => {
          this.submitPasswordStepperWorking = false;
        })
      )
      .subscribe(
        () => {
          return;
        },
        (error) => {
          this.matSnackbar.open(error.message, 'OK');
        }
      );
  }

  submitVerificationStepper(event: Event) {
    const code = this.submitVerificationFormGroup.get('verificationCode')?.value;
    if (code) {
      this.submitVerificationStepperWorking = true;
      const invitation = this.invitation.getValue();
      if (!invitation) {
        this.matSnackbar.open('No invitation', 'ok');
        return;
      }
      const request: SubmitEmailVerificationCodeRequest = {
        code: code,
        email: invitation.email,
        invitationUid: invitation.uid,
        userUid: invitation.userUid!,
        factorId: '',
      };
      this.registrationService.submitEmailVerificationCode(request).subscribe(
        () => {
          this.submitVerificationStepperWorking = false;
        },
        (error) => {
          this.matSnackbar.open(error.message, 'OK');
        }
      );
    } else {
      this.matSnackbar.open('Please enter a code', 'OK');
    }
  }

  tryResendVerificationCode(event: Event) {
    event.preventDefault();
    if (!this.resendVerificationCodeTimer.count) {
      this.requestVerificationCode();
    } else {
      this.openVerificationWaitDialog();
    }
  }

  openVerificationWaitDialog() {
    this.dialog.open(EmailVerificationDialogComponent);
  }

  requestVerificationCode() {
    const invitation = this.invitation.getValue();
    if (!invitation) {
      this.matSnackbar.open('No invitation', 'ok');
      return;
    }
    const request: RequestEmailVerificationCodeRequest = {
      email: invitation.email,
      invitationUid: invitation.uid,
    };
    this.registrationService.requestEmailVerificationCode(request).subscribe(
      () => {
        this.resendVerificationCodeTimer.startTimer();
        this.dialog.open(EmailVerificationDialogComponent);
      },
      (error) => {
        this.matSnackbar.open(error.message, 'OK');
      }
    );
  }

  submitProfileStepper(event: Event) {
    const firstName = this.createProfileFormGroup.get('firstName')?.value;
    const lastName = this.createProfileFormGroup.get('lastName')?.value;
    const telephone = this.createProfileFormGroup.get('telephone')?.value;
    const npi = this.createProfileFormGroup.get('npi')?.value;
    const invitaion = this.invitation.getValue()!;

    const data: SetProfileRequest = {
      userUid: invitaion.userUid!,
      email: invitaion.email,
      invitationUid: invitaion.uid,
      name: {
        first: firstName,
        last: lastName,
      },
      telephone: telephone,
      npi: npi,
    };

    this.invitationSubscription?.unsubscribe();
    this.registrationService.setProfile(data).subscribe(
      () => {
        this.router.navigate(['/']);
      },
      (error) => {
        this.matSnackbar.open(error.message, 'OK');
      }
    );
  }

  async onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 1) {
      this.resendVerificationCodeTimer.startTimer();
    }
    await this.performance.trace(`Registration step changed to: ${event.selectedStep.label}`);
  }
}
