import { LOGIN_CALLBACK_ROUTE } from 'src/app/app-routing.module';

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBTWPsVeOdATabOOHMtxQDJbaHt-dXf7LE',
    authDomain: 'hcp-portal-test.firebaseapp.com',
    projectId: 'hcp-portal-test',
    storageBucket: 'hcp-portal-test.appspot.com',
    messagingSenderId: '264859074958',
    appId: '1:264859074958:web:1c2d1b2a8f12911edf64df',
    measurementId: 'G-22CQ8PX4SD',
  },
  okta_domain: 'mylivanova.oktapreview.com',
  okta_clientId: '0oa179hkn22yAP7Ov0h8',
  redirectUri: `https://test.mylivanova.com/${LOGIN_CALLBACK_ROUTE}`,
};
